import { gql, useQuery } from '@apollo/client';
import { DomainResources } from '../../../models/types';

export interface DomainResourceVars {
  domainIdentifier: string;
}

export interface DomainResourcesData {
  domain: {
    domainIdentifier: string;
    esVersion: string;
    cellId: string;
    HAProxyVersion: string;
    HAProxyType: string;
    resources: {
      ALL: DomainResources;
    };
    DomainCreateDateTime: string;
  };
}

export const RESOURCE_INFO = gql`
  query getDomainDetails($domainIdentifier: String!) {
    domain(domainIdentifier: $domainIdentifier) {
      domainIdentifier
      esVersion
      cellId
      HAProxyVersion
      HAProxyType
      OpenSearchDeploymentType
      pinnedVFIDomain
      pinnedVFIClient
      IsSensitiveDomain
      IsSensitiveClient
      IsDomainIsolated
      carbonDomain
      DomainCreateDateTime
      PackageTypeAssociatedOnDomain
      dragonstoneDomain
      IsRemotePublicationEnabled
      resources {
        ALL
      }
    }
  }
`;

export const useResourceInfoQuery = (vars: DomainResourceVars) => {
  return useQuery<DomainResourcesData, DomainResourceVars>(RESOURCE_INFO, {
    variables: { ...vars },
    errorPolicy: 'all',
  });
};
